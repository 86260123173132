import React from 'react';
import loadingImage from "assets/img/others/loading_icon_new.gif";
    
const Loading = ({ show }) => {
    return show && (
        // <div className='text-center p-4 overlay_loader_bg'>
        //     <div className='loader_img'>
        //         <img src={loadingImage} />
        //     </div>
        // </div>

        <div className="loader">
            <div className="spinner"></div>
        </div>
    );
}

export default Loading;