import React, { useState } from 'react';

import { Icon } from '@chakra-ui/react';
import { MdDashboard, MdChat, MdExplore, MdOutlineMedication,MdLock,MdStore,MdPerson,MdOutlineHistoryToggleOff } from 'react-icons/md';
import { FaHandshake } from "react-icons/fa6";
import { HiLightBulb } from "react-icons/hi";
import { MdAccountCircle } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";


// Admin Imports
import DashboardsDefault from 'views/admin/dashboards/default';
import DashboardsRTLDefault from 'views/admin/dashboards/rtl';
import DashboardsCarInterface from 'views/admin/dashboards/carInterface';
import DashboardsSmartHome from 'views/admin/dashboards/smartHome';

//Property imports
import PropertiesOverview from 'views/admin/properties/overview';
import NewProperty from 'views/admin/properties/newProperty';
import EditProperty from 'views/admin/properties/editProperty';
import PropertyPriority from 'views/admin/properties/priority';

// NFT Imports
import NFTMarketplace from 'views/admin/nfts/marketplace';
import NFTPage from 'views/admin/nfts/page';
import NFTCollection from 'views/admin/nfts/collection';
import NFTProfile from 'views/admin/nfts/profile';

// Main Imports
import AccountBilling from 'views/admin/main/account/billing';
import AccountApplications from 'views/admin/main/account/application';
import AccountInvoice from 'views/admin/main/account/invoice';
import AccountSettings from 'views/admin/main/account/settings';
import AccountAllCourses from 'views/admin/main/account/courses';
import AccountCoursePage from 'views/admin/main/account/coursePage';

import UserNew from 'views/admin/main/users/newUser';
import UsersOverview from 'views/admin/main/users/overview';
import UsersReports from 'views/admin/main/users/reports';
import EditUser from 'views/admin/main/users/editUser';

import ProfileSettings from 'views/admin/main/profile/settings';
import ProfileOverview from 'views/admin/main/profile/overview';
import ProfileNewsfeed from 'views/admin/main/profile/newsfeed';

import ApplicationsKanban from 'views/admin/main/applications/kanban';
import ApplicationsDataTables from 'views/admin/main/applications/dataTables';
import ApplicationsCalendar from 'views/admin/main/applications/calendar';

import EcommerceNewProduct from 'views/admin/main/ecommerce/newProduct';
import EcommerceProductOverview from 'views/admin/main/ecommerce/overviewProduct';
import EcommerceProductSettings from 'views/admin/main/ecommerce/settingsProduct';
import EcommerceProductPage from 'views/admin/main/ecommerce/pageProduct';
import EcommerceOrderList from 'views/admin/main/ecommerce/orderList';
import EcommerceOrderDetails from 'views/admin/main/ecommerce/orderDetails';
import EcommerceReferrals from 'views/admin/main/ecommerce/referrals';

// Others
import OthersNotifications from 'views/admin/main/others/notifications';
import OthersPricing from 'views/admin/main/others/pricing';
import OthersError from 'views/admin/main/others/404';
import Messages from 'views/admin/main/others/messages';

// Auth Imports
import ForgotPasswordCentered from 'views/auth/forgotPassword/ForgotPasswordCentered.jsx';
import ForgotPasswordDefault from 'views/auth/forgotPassword/ForgotPasswordDefault.jsx';
import LockCentered from 'views/auth/lock/LockCentered.jsx';
import LockDefault from 'views/auth/lock/LockDefault.jsx';
import SignInCentered from 'views/auth/signIn/SignInCentered.jsx';
import SignInDefault from 'views/auth/signIn/SignInDefault.jsx';
import SignUpCentered from 'views/auth/signUp/SignUpCentered.jsx';
import SignUpDefault from 'views/auth/signUp/SignUpDefault.jsx';
import VerificationCentered from 'views/auth/verification/VerificationCentered.jsx';
import VerificationDefault from 'views/auth/verification/VerificationDefault.jsx';

import DeveloperOverview from 'views/admin/main/developers/overview';
import DeveloperNew from 'views/admin/main/developers/newDeveloper';
import EditDeveloper from 'views/admin/main/developers/editDeveloper';

import PropertyTypesOverview from 'views/admin/main/property-types/overview';
import PropertyTypeNew from 'views/admin/main/property-types/newPropertyType';
import EditPropertyType from 'views/admin/main/property-types/editPropertyType';

import StandoutAmenitiesOverview from 'views/admin/main/standout-amenities/overview';
import StandoutAmenitiesNew from 'views/admin/main/standout-amenities/newStandoutAmenities';
import EditStandoutAmenities from 'views/admin/main/standout-amenities/editStandoutAmenities';

import DoctorOverview from 'views/admin/doctors/overview';
import DoctorNew from 'views/admin/doctors/newDoctor';
import EditDoctor from 'views/admin/doctors/editDoctor';

import PatientOverview from 'views/admin/patients/overview';
import PatientNew from 'views/admin/patients/newPatient';
import EditPatient from 'views/admin/patients/editPatient';

import BrokerageOverview from 'views/admin/brokerage/overview';
import BrokerageNew from 'views/admin/brokerage/new';
import EditBrokerage from 'views/admin/brokerage/edit';

import OwnerOverview from 'views/admin/owner/overview';
import OwnerNew from 'views/admin/owner/new';
import EditOwner from 'views/admin/owner/edit';

import BusinessOverview from 'views/admin/business/overview';
import BusinessNew from 'views/admin/business/new';
import EditBusiness from 'views/admin/business/edit';

import StoryOverview from 'views/admin/story/overview';

import MedicalRecordNew from 'views/admin/medicalRecords/new';

import axios from 'axios';
import { API_BASE_URL } from 'constants/apiConstants';

// var homeItems = [];
// axios.get(API_BASE_URL+'get/sidebar',{
//   headers: {
//     Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
//   }
// })
// .then((response) => {
//   console.log(response.data.data)
//   var result = response.data.data;
//   for (let userObject of result) {
//     if(userObject.category === 'Home'){
//       var component1111 = <DashboardsDefault />;
//       homeItems.push(
//         {
//           name: userObject.name,
//           layout: '/admin',
//           path: userObject.path,
//           component:component1111,
//         }
//       );
//     }
//   }
// })
// .catch((error) => {
//   console.log(error)
// })

let search = window.location.search;
let params = new URLSearchParams(search);
let tab = params.get('tab');
if(tab === 'health-records'){
  var routes = [
    // --- Dashboards ---
    {
      name: 'Home',
      path: '/dashboards',
      icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Hospital Management Settings',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
          component: <DashboardsDefault />,
        },
        {
          name: 'Notifications',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'New Stories',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Hospital /Clinics',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Health  Records',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Ads',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Posts',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Medical Content',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
      ],
    },
  
    {
      name: 'Chat',
      path: '/properties',
      icon: <Icon as={MdChat} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Search',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Inbox',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'New Chat',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Sent',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Chat Notifications',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        }
      ],
    },
  
    {
      name: 'Explore',
      path: '/property-types',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'All',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'My Reports',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'My Health',
          path: '/dashboards/default',
          collapse: true,
          items: [
            {
              name: 'Search',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Medical Records',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Medications',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Vaccines',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Allergies',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Surgeries',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
          ],
        },
        {
          name: 'Doctors',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Stories',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Hospitals',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Clinics',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Business',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Specialities',
          path: '/dashboards/default',
          collapse: true,
          items: [
            {
              name: 'General Medice',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Internal Medicine',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Cardiology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Orthopaedic',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Rheumatology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Diet & Nutrition',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },

            {
              name: 'OB & Gynaecology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Fetal Medice',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Neonatology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Paediatrics',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'IVF & Fertility',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Dentistry',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Dermatology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Gastroenterology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Neurology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Pulmonology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Urology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },

            {
              name: 'Ophthalmology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Nephrology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'ENT',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Family Medicine',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Endocrinology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Physiotherapy',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'General Surgery',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Bariatric Surgery',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Plastic Surgery',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            }
          ],
        }
      ],
    },

    {
      name: 'My Health',
      path: '/property-types',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Search',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
          //component: <PropertyTypeNew />,
        },
        {
          name: 'Medical Records',
          layout: '/admin',
          path: '/medical-record/new',
          exact: false,
          component: <MedicalRecordNew />,
        },
        {
          name: 'Medications',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Vaccines',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Allergies',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Surgeries',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        }
      ],
    },

    {
      name: 'e-consult',
      path: '/dashboards/default?tab=health-records',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
      ],
    },
  
    {
      name: 'Patient profile',
      path: '/standout-amenities',
      icon: <Icon as={MdOutlineMedication} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Patient Overview',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
          component: <PatientNew />,
        },
        {
          name: 'Edit Information',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
          component: <PatientOverview />,
        },
      ],
    },
    
    {
      name: 'Appointments',
      path: '/property-types',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'New Appointment',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
          //component: <PropertyTypeNew />,
        },
        {
          name: 'Appointment Overview',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Upcoming Appointment',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Reschedule Appointment',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Cancel Appointment',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
      ],
    },

    {
      name: 'Women+',
      path: '/property-types',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Menstrual+',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
          //component: <PropertyTypeNew />,
        },
        {
          name: 'Pregnancy Tracking',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Menopause',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        }
      ],
    },

    {
      name: 'Track your health',
      path: '/property-types',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Sync your Device',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
          //component: <PropertyTypeNew />,
        },
        {
          name: 'Activity Tracker',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Heart Rate Monitor',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Sleep',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Water Intake',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Nutri+',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Fitness',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Recipes',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'BMI Calculator',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Insulin Check',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Stroke Calculator',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'BP Check',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        }
      ],
    },

    {
      name: 'Health forum',
      path: '/property-types',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Blogs',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
          //component: <PropertyTypeNew />,
        },
        {
          name: 'Mindfulness',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Tips',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        }
      ],
    },

    // --- Authentication ---
    {
      name: 'Authentication',
      path: '/auth',
      icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        // --- Sign In ---
        {
          name: 'Sign In',
          path: '/sign-in',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/sign-in/default',
              component: <SignInDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/sign-in/centered',
              component: <SignInCentered />,
            },
          ],
        },
        // --- Sign Up ---
        {
          name: 'Sign Up',
          path: '/sign-up',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/sign-up/default',
              component: <SignUpDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/sign-up/centered',
              component: <SignUpCentered />,
            },
          ],
        },
        // --- Verification ---
        {
          name: 'Verification',
          path: '/verification',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/verification/default',
              component: <VerificationDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/verification/centered',
              component: <VerificationCentered />,
            },
          ],
        },
        // --- Lock ---
        {
          name: 'Lock',
          path: '/lock',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/lock/default',
              component: <LockDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/lock/centered',
              component: <LockCentered />,
            },
          ],
        },
        // --- Forgot Password ---
        {
          name: 'Forgot Password',
          path: '/forgot-password',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/forgot-password/default',
              component: <ForgotPasswordDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/forgot-password/centered',
              component: <ForgotPasswordCentered />,
            },
          ],
        },
      ],
    },
  ];
}else if(tab === 'hospital-management'){
  var routes = [
    // --- Dashboards ---
    {
      name: 'Home',
      path: '/dashboards',
      icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Hospital Management Settings',
          layout: '/admin',
          path: '/dashboards/default',
          component: <DashboardsDefault />,
        },
      ],
    },
  
    {
      name: 'Patient',
      path: '/properties',
      icon: <Icon as={MdChat} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'New patient',
          layout: '/admin',
          path: '/patient/new',
          exact: false,
          component: <PatientNew />,
        },
        {
          name: 'Patient Overview',
          layout: '/admin',
          path: '/patient/overview',
          exact: false,
          component: <PatientOverview />,
        },
        {
          name: 'Patient Check-in / Checkout',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
          //component: <EditPatient />
        },
        {
          name: 'Patient Reports',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
          //component: <EditPatient />
        }
      ],
    },
  
    {
      name: 'Appointment',
      path: '/property-types',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'New Appointment',
          layout: '/admin',
          path: '/patient/new',
          exact: false,
          component: <PatientNew />,
        },
        {
          name: 'Appointment Overview',
          layout: '/admin',
          path: '/patient/overview',
          exact: false,
          component: <PatientOverview />,
        },
        {
          name: 'Manage Appointment',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
          //component: <EditPatient />
        },
        {
          name: 'Appointment Status',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
          //component: <EditPatient />
        },
        {
          name: 'Reminders',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
          //component: <EditPatient />
        }
      ],
    },  
  
    {
      name: 'Nurse',
      path: 'agents',
      icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Patients',
          layout: '/admin',
          path: '/patient/new',
          exact: false,
          component: <PatientNew />,
        },
        {
          name: 'Appointments',
          layout: '/admin',
          path: '/patient/overview',
          exact: false,
          component: <PatientOverview />,
        },
        {
          name: 'Vitals',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
          //component: <EditPatient />
        },
        {
          name: 'Medications',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
          //component: <EditPatient />
        },
        {
          name: 'Procedures',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
          //component: <EditPatient />
        },
        {
          name: 'Tasks',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
          //component: <EditPatient />
        },
        {
          name: 'Reports',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
          //component: <EditPatient />
        }
      ],
    },
  
    {
      name: 'Doctor',
      path: 'broker',
      icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'New Doctor',
          layout: '/admin',
          path: '/doctor/new',
          exact: false,
          component: <DoctorNew />,
        },
        {
          name: 'Doctor Overview',
          layout: '/admin',
          path: '/doctor/overview',
          exact: false,
          component: <DoctorOverview />,
        },
        {
          name: 'Patients',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Appointments',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Consultations',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Prescriptions',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Notes',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Reports',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
      ],
    },
  
    {
      name: 'Insurance',
      path: 'developers',
      icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Claims',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
          //component: <DeveloperNew />,
        },
        {
          name: 'Insurance Verification',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
          //component: <DeveloperOverview />,
        },
        {
          name: 'Payer Contracts',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
          //component: <EditDeveloper />
        },
        {
          name: 'Reports',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
          //component: <EditDeveloper />
        }
      ],
    },
  
    {
      name: 'Billing',
      path: 'brokerages',
      icon: <Icon as={FaHandshake} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Patients',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Invoice',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Payments',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'DRG Calculator',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'ICD - 10 Codes',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'CPT Codes',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Reports',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        }
      ],
    },
  
    {
      name: 'Pharmacy',
      path: 'owner',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Patients',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Prescriptions',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Inventory',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Reports',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        }
      ],
    },

    {
      name: 'HR',
      path: 'owner',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Staff Management',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Employee Records',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Payroll',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Compliance',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Reports',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        }
      ],
    },

    {
      name: 'Finance',
      path: 'owner',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Revenue',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Expenses',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Budgeting',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Profit',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Audits',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Tax Compliance',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Reports',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        }
      ],
    },

    {
      name: 'Procurement',
      path: 'owner',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Inventory Management',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Supplier  Management',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Reports',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        }
      ],
    },

    {
      name: 'Home Healthcare',
      path: 'owner',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
      ],
    },

    {
      name: 'Management+',
      path: 'owner',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
      ],
    },

    {
      name: 'Reports',
      path: 'owner',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
      ],
    },

    {
      name: 'Facility',
      path: 'owner',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
      ],
    },

    {
      name: 'Call Centre',
      path: 'owner',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'New Agent',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Agent Overview',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Recorded Calls',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Call Management',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Performance',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Reports',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        },
        {
          name: 'Settings',
          layout: '/admin',
          path: '/dashboards/default?tab=hospital-management',
          exact: false,
        }
      ],
    },

    {
      name: 'PAC System',
      path: 'owner',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
      ],
    },

    {
      name: 'Maintenance',
      path: 'owner',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
      ],
    },
  
    // --- Authentication ---
    {
      name: 'Authentication',
      path: '/auth',
      icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        // --- Sign In ---
        {
          name: 'Sign In',
          path: '/sign-in',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/sign-in/default',
              component: <SignInDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/sign-in/centered',
              component: <SignInCentered />,
            },
          ],
        },
        // --- Sign Up ---
        {
          name: 'Sign Up',
          path: '/sign-up',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/sign-up/default',
              component: <SignUpDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/sign-up/centered',
              component: <SignUpCentered />,
            },
          ],
        },
        // --- Verification ---
        {
          name: 'Verification',
          path: '/verification',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/verification/default',
              component: <VerificationDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/verification/centered',
              component: <VerificationCentered />,
            },
          ],
        },
        // --- Lock ---
        {
          name: 'Lock',
          path: '/lock',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/lock/default',
              component: <LockDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/lock/centered',
              component: <LockCentered />,
            },
          ],
        },
        // --- Forgot Password ---
        {
          name: 'Forgot Password',
          path: '/forgot-password',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/forgot-password/default',
              component: <ForgotPasswordDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/forgot-password/centered',
              component: <ForgotPasswordCentered />,
            },
          ],
        },
      ],
    },
  ];
}else if(tab === 'medical-tourism'){
  var routes = [
    // --- Dashboards ---
    {
      name: 'Home',
      path: '/dashboards',
      icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Medical Tourism Settings',
          layout: '/admin',
          path: '/dashboards/default',
          component: <DashboardsDefault />,
        },
      ],
    },
  
    // --- Properties ---
    {
      name: 'Research & Destination Selection',
      path: '/owner',
      icon: <Icon as={MdChat} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Treatments',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Research Destinations',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
        },
        {
          name: 'Providers',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        }
      ],
    },
  
    {
      name: 'Patient Inquiry',
      path: '/owner',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'General Information',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Case of Emergency',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
        },
        {
          name: 'Medical Conditions',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'For Women',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Attach Medical Documents',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        }
      ],
    },
  
    {
      name: 'Consultation & Assessment',
      path: '/standout-amenities',
      icon: <Icon as={MdOutlineMedication} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Initial Consultation',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Diagnostic Testing & Screening',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
        },
        {
          name: 'Risk Assessment',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Documentation & Record Keeping',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        }
      ],
    },

    {
      name: 'Treatment Planning',
      path: 'agents',
      icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Case Review & Problem Identification',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Goal Setting',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
        },
        {
          name: 'Timeline & Scheduling',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Vaccinations & Immunisations',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Progress Tracking & Outcome Measurement',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        }
      ],
    },
  
    {
      name: 'Pre-Treatment Preparation',
      path: 'owner',
      icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Assessment & Evaluation',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Setting Treatment Goals',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
        },
        {
          name: 'Treatment Plan Development',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Client Education',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Preparation of Treatment Environment',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Consent & Documentation ',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Logistical Arrangements',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Addressing Financial & Insurance Concerns',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Emotional & Psychological Preparation',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        }
      ],
    },

    {
      name: 'Travel & Accommodation',
      path: 'owner',
      icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Travel Planning ',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Accommodation Planning ',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
        },
        {
          name: 'Travel Documentation',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Transportation Logistics',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Budgeting & Cost Management',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Travel Insurance',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Travel Checklist',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Customer Support & Communication',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        }
      ],
    },

    {
      name: 'Treatment & Recovery',
      path: 'owner',
      icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Administering the Treatment',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Managing Side Effects & Complications',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
        },
        {
          name: 'Supportive Care',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Treatment Adjustment',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Post-Treatment Monitoring',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Rest & Rehabilitation',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Recovery Plan Development',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Post- Treatment Care Instructions',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Psychological & Emotional Support',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Gradual Return to Normal Activities',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Ongoing Support & Follow-up',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Lifestyle Adjustment & Maintenance ',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Discharge & Long-Term Care Planning',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        }
      ],
    },

    {
      name: 'Follow-up & Aftercare ',
      path: 'owner',
      icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Follow-up Appointment',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Progress Assessment',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
        },
        {
          name: 'Adjustment to Treatment Plan',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Addressing Complications or Setbacks',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Client Education on Ongoing Care',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Reviewing Long-Term Health Golas',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Post- Treatment Monitoring',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Psychosocial Support',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Lifestyle & Health Maintenance ',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Ongoing Medications & Therapies',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Follow-up Assessment & Reviews',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Discharge Planning',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        },
        {
          name: 'Relapse Prevention & Maintenance',
          layout: '/admin',
          path: '/dashboards/default?tab=medical-tourism',
          exact: false,
        }
      ],
    },
    
    // --- Authentication ---
    {
      name: 'Authentication',
      path: '/auth',
      icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        // --- Sign In ---
        {
          name: 'Sign In',
          path: '/sign-in',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/sign-in/default',
              component: <SignInDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/sign-in/centered',
              component: <SignInCentered />,
            },
          ],
        },
        // --- Sign Up ---
        {
          name: 'Sign Up',
          path: '/sign-up',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/sign-up/default',
              component: <SignUpDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/sign-up/centered',
              component: <SignUpCentered />,
            },
          ],
        },
        // --- Verification ---
        {
          name: 'Verification',
          path: '/verification',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/verification/default',
              component: <VerificationDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/verification/centered',
              component: <VerificationCentered />,
            },
          ],
        },
        // --- Lock ---
        {
          name: 'Lock',
          path: '/lock',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/lock/default',
              component: <LockDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/lock/centered',
              component: <LockCentered />,
            },
          ],
        },
        // --- Forgot Password ---
        {
          name: 'Forgot Password',
          path: '/forgot-password',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/forgot-password/default',
              component: <ForgotPasswordDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/forgot-password/centered',
              component: <ForgotPasswordCentered />,
            },
          ],
        },
      ],
    },
  ];
}else{
  var routes = [
    // --- Dashboards ---
    {
      name: 'Home',
      path: '/dashboards',
      icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Hospital Management Settings',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
          component: <DashboardsDefault />,
        },
        {
          name: 'Notifications',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'New Stories',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Hospital /Clinics',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Health  Records',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Ads',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Posts',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Medical Content',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
      ],
    },
  
    {
      name: 'Chat',
      path: '/properties',
      icon: <Icon as={MdChat} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Search',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Inbox',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'New Chat',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Sent',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Chat Notifications',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        }
      ],
    },
  
    {
      name: 'Explore',
      path: '/property-types',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'All',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'My Reports',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'My Health',
          path: '/dashboards/default',
          collapse: true,
          items: [
            {
              name: 'Search',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Medical Records',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Medications',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Vaccines',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Allergies',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Surgeries',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
          ],
        },
        {
          name: 'Doctors',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Stories',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Hospitals',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Clinics',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Business',
          layout: '/admin',
          path: '/dashboards/default',
          exact: false,
        },
        {
          name: 'Specialities',
          path: '/dashboards/default',
          collapse: true,
          items: [
            {
              name: 'General Medice',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Internal Medicine',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Cardiology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Orthopaedic',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Rheumatology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Diet & Nutrition',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },

            {
              name: 'OB & Gynaecology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Fetal Medice',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Neonatology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Paediatrics',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'IVF & Fertility',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Dentistry',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Dermatology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Gastroenterology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Neurology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Pulmonology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Urology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },

            {
              name: 'Ophthalmology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Nephrology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'ENT',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Family Medicine',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Endocrinology',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Physiotherapy',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'General Surgery',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Bariatric Surgery',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Plastic Surgery',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            }
          ],
        }
      ],
    },

    {
      name: 'My Health',
      path: '/property-types',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Search',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
          //component: <PropertyTypeNew />,
        },
        {
          name: 'Medical Records',
          layout: '/admin',
          path: '/medical-record/new',
          exact: false,
          component: <MedicalRecordNew />,
        },
        {
          name: 'Medications',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Vaccines',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Allergies',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Surgeries',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        }
      ],
    },

    {
      name: 'e-consult',
      path: '/dashboards/default?tab=health-records',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
      ],
    },
  
    {
      name: 'Patient profile',
      path: '/standout-amenities',
      icon: <Icon as={MdOutlineMedication} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Patient Overview',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
          component: <PatientNew />,
        },
        {
          name: 'Edit Information',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
          component: <PatientOverview />,
        },
      ],
    },
    
    {
      name: 'Appointments',
      path: '/property-types',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'New Appointment',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
          //component: <PropertyTypeNew />,
        },
        {
          name: 'Appointment Overview',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Upcoming Appointment',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Reschedule Appointment',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Cancel Appointment',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
      ],
    },

    {
      name: 'Women+',
      path: '/property-types',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Menstrual+',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
          //component: <PropertyTypeNew />,
        },
        {
          name: 'Pregnancy Tracking',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Menopause',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        }
      ],
    },

    {
      name: 'Track your health',
      path: '/property-types',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Sync your Device',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
          //component: <PropertyTypeNew />,
        },
        {
          name: 'Activity Tracker',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Heart Rate Monitor',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Sleep',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Water Intake',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Nutri+',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Fitness',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Recipes',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'BMI Calculator',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Insulin Check',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Stroke Calculator',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'BP Check',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        }
      ],
    },

    {
      name: 'Health forum',
      path: '/property-types',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Blogs',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
          //component: <PropertyTypeNew />,
        },
        {
          name: 'Mindfulness',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        },
        {
          name: 'Tips',
          layout: '/admin',
          path: '/dashboards/default?tab=health-records',
          exact: false,
        }
      ],
    },

    // --- Authentication ---
    {
      name: 'Authentication',
      path: '/auth',
      icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        // --- Sign In ---
        {
          name: 'Sign In',
          path: '/sign-in',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/sign-in/default',
              component: <SignInDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/sign-in/centered',
              component: <SignInCentered />,
            },
          ],
        },
        // --- Sign Up ---
        {
          name: 'Sign Up',
          path: '/sign-up',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/sign-up/default',
              component: <SignUpDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/sign-up/centered',
              component: <SignUpCentered />,
            },
          ],
        },
        // --- Verification ---
        {
          name: 'Verification',
          path: '/verification',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/verification/default',
              component: <VerificationDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/verification/centered',
              component: <VerificationCentered />,
            },
          ],
        },
        // --- Lock ---
        {
          name: 'Lock',
          path: '/lock',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/lock/default',
              component: <LockDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/lock/centered',
              component: <LockCentered />,
            },
          ],
        },
        // --- Forgot Password ---
        {
          name: 'Forgot Password',
          path: '/forgot-password',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/forgot-password/default',
              component: <ForgotPasswordDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/forgot-password/centered',
              component: <ForgotPasswordCentered />,
            },
          ],
        },
      ],
    },
  ];
}

export default routes;