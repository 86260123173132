/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  FormLabel,
  useToast,
  Radio, 
  RadioGroup,
  Select,
} from '@chakra-ui/react';
// Custom components

import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Dropzone from 'react-dropzone';

export default function NewPatient() {
  const navigate = useNavigate();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [activeBullets, setActiveBullets] = useState({
    user: true,
    educationalInformation: false,
    professionalInformation: false,
    publicationResearch: false,
    accessibility: false,
  });
  
  const [input,setInput] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email_id: "",
    mobile_number: "",
    passcode: "",
    place_of_birth: "",
    nationality: "",
    blood_type: "",
    height: "",
    weight: "",
    bmi_value: "",
    occupation: "",
    marital_status: "",
    allergies_reactions: "",
    emergency_name: "",
    relationship: "",
    emergency_mobile_number: "",
    emergency_email_id: "",
    communication_preference: "",
    preferred_language: "",
  });
  const [errors, setErrors] = useState({});
  const toast = useToast();
  const [patientId, setPatientId] = useState('');
  const [gender, setGender] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
  };

  const handleGenderChange = (value) => {
    setGender(value);
  };

  const validateForm = (data) => {
    const errors = {};
    
    if (!data.first_name) {
      errors.first_name = 'First name field is required';
    }

    if (!data.last_name) {
      errors.last_name = 'Last name field is required';
    }
    
    if (!data.username) {
      errors.username = 'Username field is required';
    }

    if (!data.email_id) {
      errors.email_id = 'Email field is required';
    }

    if (!gender) {
      errors.gender = 'Gender field is required';
    }

    if (!data.mobile_number) {
      errors.mobile_number = 'Phone number field is required';
    }

    if (!data.passcode) {
      errors.passcode = 'Passcode field is required';
    }

    // if (!data.date_of_birth) {
    //   errors.date_of_birth = 'Date of birth field is required';
    // }

    if (!data.place_of_birth) {
      errors.place_of_birth = 'Place of birth field is required';
    }

    if (!data.nationality) {
      errors.nationality = 'Nationality field is required';
    }

    if (!data.blood_type) {
      errors.blood_type = 'Blood type field is required';
    }

    if (!data.height) {
      errors.height = 'Height field is required';
    }

    if (!data.weight) {
      errors.weight = 'Weight field is required';
    }

    if (!data.bmi_value) {
      errors.bmi_value = 'Bmi value field is required';
    }

    if (!data.marital_status) {
      errors.marital_status = 'Marital status field is required';
    }

    if (!data.allergies_reactions) {
      errors.allergies_reactions = 'Allergies reaction field is required';
    }

    if (!data.occupation) {
      errors.occupation = 'Occupation field is required';
    }

    if (!data.alternate_mobile_number) {
      errors.alternate_mobile_number = 'Alternate mobile number field is required';
    }
    return errors;
  };

  const handleSubmitPersonalDetailsInfo = (e) => {
    e.preventDefault();
    const newErrors = validateForm(input);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const payload = {
        "first_name":input.first_name,
        "last_name":input.last_name,
        "username":input.username,
        "email":input.email,
        "gender":gender,
        "mobile_number":input.mobile_number,
        "passcode":input.passcode,
        "date_of_birth":input.date_of_birth,
        "place_of_birth": input.place_of_birth,
        "nationality": input.nationality
      };
      setPatientId(1);
      emergencyContactInformationTab.current.click();
    }
  };

  const validateEmergencyInformationForm = (data) => {
    const errors = {};
    
    if (!data.emergency_name) {
      errors.emergency_name = 'Name field is required';
    }

    if (!data.relationship) {
      errors.relationship = 'Relationship field is required';
    }
    
    if (!data.emergency_mobile_number) {
      errors.emergency_mobile_number = 'Mobile number field is required';
    }

    if (!data.emergency_email_id) {
      errors.emergency_email_id = 'Email id field is required';
    }
    return errors;
  };

  const handleSubmitEmergencyInformationInfo = (e) => {
    e.preventDefault();

    if(patientId === ''){
      toast({ title: 'Please fill personal details first.',status: 'error',isClosable: true,position: 'top'})
      return false;
    }

    const newErrors = validateEmergencyInformationForm(input);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const payload = {
        "medical_degree":input.medical_degree,
        "last_nmedical_schoolame":input.medical_school,
        "year_of_graduation":input.year_of_graduation,
        "specialisation":input.specialisation,
        "board_certifications":input.board_certifications
      }
      professionalInformationTab.current.click();
    }
  };

  const validateAccessibilityForm = (data) => {
    const errors = {};
    
    if (!data.communication_preference) {
      errors.communication_preference = 'Communication preference field is required';
    }

    if (!data.preferred_language) {
      errors.preferred_language = 'Preferred language field is required';
    }
    return errors;
  };

  const handleSubmitAccessibility = (e) => {
    e.preventDefault();

    if(patientId === ''){
      toast({ title: 'Please fill Emergency contact information first.',status: 'error',isClosable: true,position: 'top'})
      return false;
    }

    const newErrors = validateAccessibilityForm(input);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const payload = {
        "medical_degree":input.medical_degree,
        "last_nmedical_schoolame":input.medical_school,
        "year_of_graduation":input.year_of_graduation,
        "specialisation":input.specialisation,
        "board_certifications":input.board_certifications
      }
      publicationResearchTab.current.click();
    }
  };

  const validateExportReportForm = (data) => {
    const errors = {};
    
    if (!data.from_date) {
      errors.from_date = 'From date field is required';
    }

    if (!data.to_date) {
      errors.to_date = 'To date field is required';
    }
    return errors;
  };

  const handleSubmitExportReportInfo = (e) => {
    e.preventDefault();

    if(patientId === ''){
      toast({ title: 'Please fill accessibility information first.',status: 'error',isClosable: true,position: 'top'})
      return false;
    }

    const newErrors = validateExportReportForm(input);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const payload = {
        "publications":input.publications,
        "research_interests":input.research_interests
      }
      accessibilityTab.current.click();
    }
  };

  const handleSubmitPartnerPairInfo = (e) => {
    e.preventDefault();

    if(patientId === ''){
      toast({ title: 'Please fill export report information first.',status: 'error',isClosable: true,position: 'top'})
      return false;
    }

    navigate("/admin/patient/overview");
  };

  
  const userTab = React.useRef();
  const emergencyContactInformationTab = React.useRef();
  const professionalInformationTab = React.useRef();
  const publicationResearchTab = React.useRef();
  const accessibilityTab = React.useRef();

  return (
    <Flex
        direction="column"
        minH="100vh"
        align="center"
        pt={{ sm: '125px', lg: '75px' }}
        position="relative"
      >
        <Box
          h="45vh"
          bgGradient="linear(to-b, brand.400, brand.600)"
          position="absolute"
          w="100%"
          borderRadius="20px"
        />
  
        <Tabs
          variant="unstyled"
          zIndex="0"
          mt={{ base: '60px', md: '165px' }}
          display="flex"
          flexDirection="column"
        >
          <TabList
            display="flex"
            alignItems="center"
            alignSelf="center"
            justifySelf="center"
          >
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={userTab}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
              onClick={() =>
                setActiveBullets({
                  user: true,
                  educationalInformation: false,
                  professionalInformation: false,
                  publicationResearch: false,
                  accessibility: false,
                })
              }
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: '120px', md: '250px', lg: '300px' },
                  height: '3px',
                  bg: activeBullets.user ? 'white' : 'brand.400',
                  left: { sm: '12px', md: '30px' },
                  top: {
                    sm: activeBullets.user ? '6px' : '4px',
                    md: null,
                  },
                  position: 'absolute',
                  bottom: activeBullets.user ? '40px' : '38px',
  
                  transition: 'all .3s ease',
                }}
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.user ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.user ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.user ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  Personal Details
                </Text>
              </Flex>
            </Tab>

            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={emergencyContactInformationTab}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
              onClick={() =>
                setActiveBullets({
                  user: true,
                  educationalInformation: true,
                  professionalInformation: false,
                  publicationResearch: false,
                  accessibility: false,
                })
              }
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: '120px', md: '250px', lg: '300px' },
                  height: '3px',
                  bg: activeBullets.educationalInformation ? 'white' : 'brand.400',
                  left: { sm: '12px', md: '30px' },
                  top: {
                    sm: activeBullets.educationalInformation ? '6px' : '4px',
                    md: null,
                  },
                  position: 'absolute',
                  bottom: activeBullets.educationalInformation ? '40px' : '38px',
                  transition: 'all .3s ease',
                }}
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.educationalInformation ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.educationalInformation ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.educationalInformation ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  Emergency Contact Information
                </Text>
              </Flex>
            </Tab>

            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={professionalInformationTab}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
              onClick={() =>
                setActiveBullets({
                  user: true,
                  educationalInformation: true,
                  professionalInformation: true,
                  publicationResearch: false,
                  accessibility: false,
                })
              }
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: '120px', md: '250px', lg: '300px' },
                  height: '3px',
                  bg: activeBullets.professionalInformation ? 'white' : 'brand.400',
                  left: { sm: '12px', md: '30px' },
                  top: {
                    sm: activeBullets.professionalInformation ? '6px' : '4px',
                    md: null,
                  },
                  position: 'absolute',
                  bottom: activeBullets.professionalInformation ? '40px' : '38px',
                  transition: 'all .3s ease',
                }}
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.professionalInformation ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.professionalInformation ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.professionalInformation ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  Accessibility
                </Text>
              </Flex>
            </Tab>

            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={publicationResearchTab}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
              onClick={() =>
                setActiveBullets({
                  user: true,
                  educationalInformation: true,
                  professionalInformation: true,
                  publicationResearch: true,
                  accessibility: false,
                })
              }
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: '120px', md: '250px', lg: '300px' },
                  height: '3px',
                  bg: activeBullets.publicationResearch ? 'white' : 'brand.400',
                  left: { sm: '12px', md: '30px' },
                  top: {
                    sm: activeBullets.publicationResearch ? '6px' : '4px',
                    md: null,
                  },
                  position: 'absolute',
                  bottom: activeBullets.publicationResearch ? '40px' : '38px',
  
                  transition: 'all .3s ease',
                }}
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.publicationResearch ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.publicationResearch ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.publicationResearch ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  Export Report
                </Text>
              </Flex>
            </Tab>

            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={accessibilityTab}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
              onClick={() =>
                setActiveBullets({
                  user: true,
                  educationalInformation: true,
                  professionalInformation: true,
                  publicationResearch: true,
                  accessibility: true,
                })
              }
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: '120px', md: '250px', lg: '300px' },
                  height: '3px',
                  bg: activeBullets.accessibility ? 'white' : 'brand.400',
                  left: { sm: '12px', md: '32px' },
                  top: '6px',
                  position: 'absolute',
                  bottom: activeBullets.accessibility ? '40px' : '38px',
  
                  transition: 'all .3s ease',
                }}
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.accessibility ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.accessibility ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.accessibility ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  Partner Pair
                </Text>
              </Flex>
            </Tab>

          </TabList>

          <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                  Personal Details
                </Text>
                <form onSubmit={handleSubmitPersonalDetailsInfo}>
                  <Flex direction="column" w="100%">

                      <Stack direction="column" spacing="20px" margin="20px 0px">
                        <Flex direction="column">

                        <FormLabel
                          ms="10px"
                          htmlFor="property_logo"
                          fontSize="sm"
                          color=''
                          fontWeight="bold"
                          _hover={{ cursor: 'pointer' }}
                        >
                          Profile Image
                        </FormLabel>

                        <Dropzone onDrop='' accept="image/*" maxFiles={1}>
                          {({getRootProps, getInputProps, isDragActive, isDragAccept,isDragReject }) => {
                            const additionalClass = isDragAccept ? "accept" :isDragReject ? "reject" : "";

                            return (
                              <div
                              {...getRootProps({
                                className: `dropzone ${additionalClass}`
                              })}
                              >
                                <input {...getInputProps()} />
                                <p>Drag'n'drop images,or click to select files</p>
                              </div>
                            );
                          }}
                        </Dropzone>
                        </Flex>
                      </Stack>

                      <Stack direction="column" spacing="20px">
                        <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <InputField
                                  mb="0px"
                                  id="first-name"
                                  placeholder="eg. Esthera"
                                  label="First Name"
                                  name="first_name"
                                  onChange={handleChange}
                                  value={input.first_name}
                              />
                              {errors.first_name &&
                                <span className="error-message">
                                    {errors.first_name}
                                </span>
                              }
                            </Flex>
                          </Stack>

                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <InputField
                                  mb="0px"
                                  id="last-name"
                                  placeholder="eg. Peterson"
                                  label="Last Name"
                                  name="last_name"
                                  onChange={handleChange}
                                  value={input.last_name}
                              />
                              {errors.last_name &&
                                <span className="error-message">
                                    {errors.last_name}
                                </span>
                              }
                            </Flex>
                          </Stack>

                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <InputField
                                  mb="0px"
                                  id="username"
                                  placeholder="eg. Simmmple"
                                  label="Username"
                                  name="username"
                                  onChange={handleChange}
                                  value={input.username}
                              />
                              {errors.username &&
                                <span className="error-message">
                                    {errors.username}
                                </span>
                              }
                            </Flex>
                          </Stack>

                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <InputField
                                  mb="0px"
                                  id="mobile_number"
                                  placeholder="eg. 1234567890"
                                  label="Mobile Number"
                                  name="mobile_number"
                                  onChange={handleChange}
                                  value={input.mobile_number}
                              />
                              {errors.mobile_number &&
                                <span className="error-message">
                                    {errors.mobile_number}
                                </span>
                              }
                            </Flex>
                          </Stack>

                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <InputField
                                  mb="0px"
                                  id="passcode"
                                  placeholder="4030120241"
                                  label="Passcode"
                                  name="passcode"
                                  onChange={handleChange}
                                  value={input.passcode}
                              />
                              {errors.passcode &&
                                <span className="error-message">
                                    {errors.passcode}
                                </span>
                              }
                            </Flex>
                          </Stack>

                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <label for="passcode" class="chakra-form__label css-186ooiv">Date Of Birth<p class="chakra-text css-72mia8"></p></label>
                              <input
                                  type='date'
                                  id="date_of_birth"
                                  placeholder="28/11/1998"
                                  label="Date Of Birth"
                                  name="date_of_birth"
                                  mb="0px"
                                  onChange={handleChange}
                                  value={input.date_of_birth}
                                  class="chakra-input css-1t1l5ix"
                              />
                              {/* {errors.date_of_birth &&
                                <span className="error-message">
                                    {errors.date_of_birth }
                                </span>
                              } */}
                            </Flex>
                          </Stack>

                          <Stack direction="column" spacing="20px" margin="20px 0px">
                              <Flex direction="column">
                                <FormLabel
                                  ms="10px"
                                  htmlFor="gender"
                                  fontSize="sm"
                                  color=''
                                  fontWeight="bold"
                                  _hover={{ cursor: 'pointer' }}
                                >
                                  Gender
                                </FormLabel>

                                <RadioGroup onChange={handleGenderChange} value={gender} name='gender'>
                                  <Stack direction='row'>
                                    <Radio value='1'>Male</Radio>
                                    <Radio value='2'>Female</Radio>
                                  </Stack>
                                </RadioGroup>
                                {errors.gender &&
                                  <span className="error-message">
                                      {errors.gender}
                                  </span>
                                }
                              </Flex>
                          </Stack>

                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <FormLabel
                                ms="10px"
                                htmlFor="place_of_birth"
                                fontSize="sm"
                                color=''
                                fontWeight="bold"
                                _hover={{ cursor: 'pointer' }}
                              >
                                Place Of Birth
                              </FormLabel>
      
                              <Select
                                id="place_of_birth"
                                value={input.place_of_birth}
                                name="place_of_birth"
                                onChange={handleChange}
                                borderRadius="16px"
                              >
                                <option value=''>Select...</option>
                                <option value='1'>test</option>
                              </Select>
                              {errors.place_of_birth &&
                                <span className="error-message">
                                    {errors.place_of_birth}
                                </span>
                              }
                            </Flex>
                          </Stack>

                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <FormLabel
                                ms="10px"
                                htmlFor="nationality"
                                fontSize="sm"
                                color=''
                                fontWeight="bold"
                                _hover={{ cursor: 'pointer' }}
                              >
                                Nationality
                              </FormLabel>
      
                              <Select
                                id="nationality"
                                value={input.nationality}
                                name="nationality"
                                onChange={handleChange}
                                borderRadius="16px"
                              >
                                <option value=''>Select...</option>
                                <option value='1'>test</option>
                              </Select>
                              {errors.nationality &&
                                <span className="error-message">
                                    {errors.nationality}
                                </span>
                              }
                            </Flex>
                          </Stack>

                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <InputField
                                  mb="0px"
                                  id="blood_type"
                                  placeholder="eg. A+"
                                  label="Blood Type"
                                  name="blood_type"
                                  onChange={handleChange}
                                  value={input.blood_type}
                              />
                              {errors.blood_type &&
                                <span className="error-message">
                                    {errors.blood_type}
                                </span>
                              }
                            </Flex>
                          </Stack>

                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <InputField
                                  mb="0px"
                                  id="height"
                                  placeholder="eg. 155.448"
                                  label="Height In CMS"
                                  name="height"
                                  onChange={handleChange}
                                  value={input.height}
                              />
                              {errors.height &&
                                <span className="error-message">
                                    {errors.height}
                                </span>
                              }
                            </Flex>
                          </Stack>

                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <InputField
                                  mb="0px"
                                  id="weight"
                                  placeholder="eg. 74"
                                  label="Weight In KGS"
                                  name="weight"
                                  onChange={handleChange}
                                  value={input.weight}
                              />
                              {errors.weight &&
                                <span className="error-message">
                                    {errors.weight}
                                </span>
                              }
                            </Flex>
                          </Stack>

                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <InputField
                                  mb="0px"
                                  id="bmi_value"
                                  placeholder="eg. 74"
                                  label="BMI Value"
                                  name="bmi_value"
                                  onChange={handleChange}
                                  value={input.bmi_value}
                              />
                              {errors.bmi_value &&
                                <span className="error-message">
                                    {errors.bmi_value}
                                </span>
                              }
                            </Flex>
                          </Stack>

                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <InputField
                                  mb="0px"
                                  id="alternate_mobile_number"
                                  placeholder="eg. 1234567890"
                                  label="Alternate Contact Number"
                                  name="alternate_mobile_number"
                                  onChange={handleChange}
                                  value={input.alternate_mobile_number}
                              />
                              {errors.alternate_mobile_number &&
                                <span className="error-message">
                                    {errors.alternate_mobile_number}
                                </span>
                              }
                            </Flex>
                          </Stack>

                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <InputField
                                  mb="0px"
                                  id="email_id"
                                  placeholder="eg. test@gmail.com"
                                  label="Email Id"
                                  name="email_id"
                                  onChange={handleChange}
                                  value={input.email_id}
                              />
                              {errors.email_id &&
                                <span className="error-message">
                                    {errors.email_id}
                                </span>
                              }
                            </Flex>
                          </Stack>

                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <FormLabel
                                ms="10px"
                                htmlFor="occupation"
                                fontSize="sm"
                                color=''
                                fontWeight="bold"
                                _hover={{ cursor: 'pointer' }}
                              >
                                Occupation
                              </FormLabel>
      
                              <Select
                                id="occupation"
                                value={input.occupation}
                                name="occupation"
                                onChange={handleChange}
                                borderRadius="16px"
                              >
                                <option value=''>Select...</option>
                                <option value='1'>Business</option>
                              </Select>
                              {errors.occupation &&
                                <span className="error-message">
                                    {errors.occupation}
                                </span>
                              }
                            </Flex>
                          </Stack>

                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <FormLabel
                                ms="10px"
                                htmlFor="marital_status"
                                fontSize="sm"
                                color=''
                                fontWeight="bold"
                                _hover={{ cursor: 'pointer' }}
                              >
                                Marital Status
                              </FormLabel>
      
                              <Select
                                id="marital_status"
                                value={input.marital_status}
                                name="marital_status"
                                onChange={handleChange}
                                borderRadius="16px"
                              >
                                <option value=''>Select...</option>
                                <option value='1'>Married</option>
                                <option value='2'>Single</option>
                              </Select>
                              {errors.marital_status &&
                                <span className="error-message">
                                    {errors.marital_status}
                                </span>
                              }
                            </Flex>
                          </Stack>

                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <FormLabel
                                ms="10px"
                                htmlFor="allergies_reactions"
                                fontSize="sm"
                                color=''
                                fontWeight="bold"
                                _hover={{ cursor: 'pointer' }}
                              >
                                Allergies & Reactions
                              </FormLabel>
      
                              <Select
                                id="allergies_reactions"
                                value={input.allergies_reactions}
                                name="allergies_reactions"
                                onChange={handleChange}
                                borderRadius="16px"
                              >
                                <option value=''>Select...</option>
                                <option value='1'>Food Allergies</option>
                              </Select>
                              {errors.allergies_reactions &&
                                <span className="error-message">
                                    {errors.allergies_reactions}
                                </span>
                              }
                            </Flex>
                          </Stack>

                        </SimpleGrid>
                      </Stack>

                      <Flex justify="space-between" mt="24px">
                        <Button
                          variant="darkBrand"
                          fontSize="sm"
                          borderRadius="16px"
                          w={{ base: '128px', md: '148px' }}
                          h="46px"
                          ms="auto"
                          type='submit'
                          // onClick={() => addressTab.current.click()}
                        >
                          Next
                        </Button>
                      </Flex>
                  </Flex>
                </form>
              </Card>
            </TabPanel>

            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                  Emergency Contact Information
                </Text>
                <form onSubmit={handleSubmitEmergencyInformationInfo}>
                  <Flex direction="column" w="100%">
                      <Stack direction="column" spacing="20px">
                        <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <InputField
                                  mb="0px"
                                  id="emergency_name"
                                  placeholder="eg. CAROLINA GABRIEL"
                                  label="Name"
                                  name="emergency_name"
                                  onChange={handleChange}
                                  value={input.emergency_name}
                              />
                              {errors.emergency_name &&
                                <span className="error-message">
                                    {errors.emergency_name}
                                </span>
                              }
                            </Flex>
                          </Stack>


                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <InputField
                                  mb="0px"
                                  id="relationship"
                                  placeholder="eg. WIFE"
                                  label="Relationship"
                                  name="relationship"
                                  onChange={handleChange}
                                  value={input.relationship}
                              />
                              {errors.relationship &&
                                <span className="error-message">
                                    {errors.relationship}
                                </span>
                              }
                            </Flex>
                          </Stack>

                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <InputField
                                  mb="0px"
                                  id="emergency_mobile_number"
                                  placeholder="eg. 1212345678"
                                  label="Mobile Number"
                                  name="emergency_mobile_number"
                                  onChange={handleChange}
                                  value={input.emergency_mobile_number}
                              />
                              {errors.emergency_mobile_number &&
                                <span className="error-message">
                                    {errors.emergency_mobile_number}
                                </span>
                              }
                            </Flex>
                          </Stack>

                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <InputField
                                  mb="0px"
                                  id="emergency_email_id"
                                  placeholder="eg. abc@gmail.com"
                                  label="Email Id"
                                  name="emergency_email_id"
                                  onChange={handleChange}
                                  value={input.emergency_email_id}
                              />
                              {errors.emergency_email_id &&
                                <span className="error-message">
                                    {errors.emergency_email_id}
                                </span>
                              }
                            </Flex>
                          </Stack>
                        </SimpleGrid>
                      </Stack>

                      <Flex justify="space-between" mt="24px">
                        <Button
                          variant="darkBrand"
                          fontSize="sm"
                          borderRadius="16px"
                          w={{ base: '128px', md: '148px' }}
                          h="46px"
                          ms="auto"
                          type='submit'
                          onClick={() => emergencyContactInformationTab.current.click()}
                        >
                          Next
                        </Button>
                      </Flex>
                  </Flex>
                </form>
              </Card>
            </TabPanel>

            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                  Accessibility
                </Text>
                <form onSubmit={handleSubmitAccessibility}>
                  <Flex direction="column" w="100%">
                      <Stack direction="column" spacing="20px">
                        <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <FormLabel
                                ms="10px"
                                htmlFor="communication_preference"
                                fontSize="sm"
                                color=''
                                fontWeight="bold"
                                _hover={{ cursor: 'pointer' }}
                              >
                                Communication Preference
                              </FormLabel>
      
                              <Select
                                id="communication_preference"
                                value={input.communication_preference}
                                name="communication_preference"
                                onChange={handleChange}
                                borderRadius="16px"
                              >
                                <option value=''>Select...</option>
                                <option value='1'>Text Messages</option>
                              </Select>
                              {errors.communication_preference &&
                                <span className="error-message">
                                    {errors.communication_preference}
                                </span>
                              }
                            </Flex>
                          </Stack>

                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <FormLabel
                                ms="10px"
                                htmlFor="preferred_language"
                                fontSize="sm"
                                color=''
                                fontWeight="bold"
                                _hover={{ cursor: 'pointer' }}
                              >
                                Preferred language
                              </FormLabel>
      
                              <Select
                                id="preferred_language"
                                value={input.preferred_language}
                                name="preferred_language"
                                onChange={handleChange}
                                borderRadius="16px"
                              >
                                <option value=''>Select...</option>
                                <option value='1'>English</option>
                              </Select>
                              {errors.preferred_language &&
                                <span className="error-message">
                                    {errors.preferred_language}
                                </span>
                              }
                            </Flex>
                          </Stack>
                        </SimpleGrid>
                      </Stack>

                      <Flex justify="space-between" mt="24px">
                        <Button
                          variant="darkBrand"
                          fontSize="sm"
                          borderRadius="16px"
                          w={{ base: '128px', md: '148px' }}
                          h="46px"
                          ms="auto"
                          type='submit'
                          //onClick={() => addressTab.current.click()}
                        >
                          Next
                        </Button>
                      </Flex>
                  </Flex>
                </form>
              </Card>
            </TabPanel>

            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                  Export Report
                </Text>
                <form onSubmit={handleSubmitExportReportInfo}>
                  <Flex direction="column" w="100%">
                      <Stack direction="column" spacing="20px">
                        <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <label for="passcode" class="chakra-form__label css-186ooiv">From Date<p class="chakra-text css-72mia8"></p></label>
                              <input
                                  type='date'
                                  mb="0px"
                                  id="from_date"
                                  name="from_date"
                                  onChange={handleChange}
                                  value={input.from_date}
                                  class="chakra-input css-1t1l5ix"
                              />
                              {errors.from_date &&
                                <span className="error-message">
                                    {errors.from_date}
                                </span>
                              }
                            </Flex>
                          </Stack>

                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <label for="passcode" class="chakra-form__label css-186ooiv">To Date<p class="chakra-text css-72mia8"></p></label>
                              <input
                                  type='date'
                                  mb="0px"
                                  id="to_date"
                                  name="to_date"
                                  onChange={handleChange}
                                  value={input.to_date}
                                  class="chakra-input css-1t1l5ix"
                              />
                              {errors.to_date &&
                                <span className="error-message">
                                    {errors.to_date}
                                </span>
                              }
                            </Flex>
                          </Stack>

                        </SimpleGrid>
                      </Stack>

                      <Flex justify="space-between" mt="24px">
                        <Button
                          variant="darkBrand"
                          fontSize="sm"
                          borderRadius="16px"
                          w={{ base: '128px', md: '148px' }}
                          h="46px"
                          ms="auto"
                          type='submit'
                          //onClick={() => addressTab.current.click()}
                        >
                          Next
                        </Button>
                      </Flex>
                  </Flex>
                </form>
              </Card>
            </TabPanel>

            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                  Partner Pair
                </Text>

                <form onSubmit={handleSubmitPartnerPairInfo}>
                  <Flex direction="column" w="100%">
                      <Stack direction="column" spacing="20px">
                        <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">

                          <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              Your partner will receive a link to download the app. He/She can view your health profiles. 
                            </Flex>
                          </Stack>

                          <Flex justify="space-between" mt="24px">
                            <Button
                                variant="darkBrand"
                                fontSize="sm"
                                borderRadius="16px"
                                w={{ base: '128px', md: '148px' }}
                                h="46px"
                                ms="auto"
                                type='submit'
                                //onClick={() => redirectToListing()}
                            >
                            Save
                            </Button>
                          </Flex>
                        </SimpleGrid>
                      </Stack>
                  </Flex>
                </form>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
  );
}
