/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react';
import { NavLink  } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
// Custom components
import { HSeparator } from 'components/separator/Separator';
import DefaultAuth from 'layouts/auth/types/Default';
// Assets
import illustration from 'assets/img/auth/auth.png';
import { FcGoogle } from 'react-icons/fc';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import axios from 'axios';
import { API_BASE_URL } from 'constants/apiConstants';
import { useGoogleLogin } from '@react-oauth/google';  
import AppleSignInButton from 'react-apple-signin-auth';
import verifiedLogo from "assets/img/dashboards/verifiedhealth.png";
import Loading from 'components/Loading';

function SignIn(props) {
  const[loading, setLoading] =useState(false);
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const googleBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.200');
  const googleText = useColorModeValue('navy.700', 'white');
  const toast = useToast()
  const googleHover = useColorModeValue(
    { bg: 'gray.200' },
    { bg: 'whiteAlpha.300' },
  );
  const googleActive = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.200' },
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [state, setState] = useState({
    email: "",
    password: "",
    successMessage: null
  })

  const handleChange = (e) => {
    const {name, value} = e.target
    setState(prevState => ({
      ...prevState,
        [name] : value
      })
    )
  }

  const handleSubmitClick = (e) => {
    
    e.preventDefault();
    if(state.email === "" || state.password === ""){
      toast({ title: "Please provide a valid input",status: 'error',isClosable: true,position: 'top'})
      return false;
    }
    if(state.email !== "" && state.password !== ""){
      const payload = {
        "email":state.email,
        "password":state.password,
      }
      axios.post(API_BASE_URL+'user/login',payload)
        .then(function (response){ 
          if(response.data.apistatus === true){
            toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
            localStorage.setItem("ACCESS_TOKEN", response.data.data.token);
            window.location.href = '/admin';
          }else if(response.data.apistatus === false){
            toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
          }
        })
        .catch(function (error){
          toast({ title: error,status: 'error',isClosable: true,position: 'top'})
        })
    }
  }; 

  const [user,setUser] = useState([]);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => toast.error('Login failed', {
      position: toast.POSITION.TOP_CENTER,
    })
  }); 

  const handleAppleSuccess = (data) => {
    const { authorization, user } = data

    const applePayload = {
      "authorizationail":authorization,
      "user":user,
    }
    axios.post(API_BASE_URL+'apple/login',applePayload)
    .then((res) => {
      if(res.data.apistatus === true){
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.setItem("ACCESS_TOKEN", res.data.data.token);
        window.location.href = '/admin';
      }else if(res.data.apistatus === false){
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    })
  }
  
  const authOptions = {
    clientId: 'properties.verified', // This is your service ID
    scope: 'email name',
    redirectURI: 'https://verified.properties/auth/sign-in/default',
    nonce: 'nonce',
    usePopup: true, // important to catch up data on the frontend
  }
   
  useEffect(
    () => {
      if(user.access_token !== undefined) {
        axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,{
          headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json'
          }
        })
        .then((res) => {
          const payload = res.data;
          axios.post(API_BASE_URL+'google/login',payload)
          .then((res) => {
            if(res.data.apistatus === true){
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
              localStorage.setItem("ACCESS_TOKEN", res.data.data.token);
              window.location.href = '/admin';
            }else if(res.data.apistatus === false){
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          })
        })
        .catch((err) => toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        }));
      }
    
      axios.interceptors.request.use(function (config) {
        setLoading(true);
        return config;
      }, function (error) {
        // Do something with request error
        return Promise.reject(error);
      });
      
      // Add a response interceptor
      axios.interceptors.response.use(function (response) {
          setLoading(false);
          return response;
        }, function (error) {
          // Any status codes that falls outside the range of 2xx cause this function to trigger
          // Do something with response error
          return Promise.reject(error);
      });
    }
  );

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection="column"
      >
        <Loading show={loading}/>
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            <img src={verifiedLogo} />
          </Heading>
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        > 
          <Button
            fontSize="sm"
            me="0px"
            mb="26px"
            py="15px"
            h="50px"
            borderRadius="16px"
            bg={googleBg}
            color={googleText}
            fontWeight="500"
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
            onClick={() => login()}
          >
            <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
            Sign in with Google
          </Button>
          
          <AppleSignInButton
          authOptions={authOptions}
          uiType="dark"
          className="apple-auth-btn"
          noDefaultStyle={false}
          onSuccess={handleAppleSuccess}
          onError={(error) => console.error(error)}
        />

          <Flex align="center" mb="25px">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              or
            </Text>
            <HSeparator />
          </Flex>
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              type="email"
              placeholder=""
              mb="24px"
              fontWeight="500"
              size="lg"
              name="email"
              onChange={handleChange}
            />
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              isRequired={true}
              display="flex"
            >
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 8 characters"
                mb="24px"
                size="lg"
                type={show ? 'text' : 'password'}
                variant="auth"
                name="password"
                onChange={handleChange}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent="space-between" align="center" mb="24px">
              <FormControl display="flex" alignItems="center">
                <Checkbox id="remember-login" colorScheme="brand" me="10px" />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  fontWeight="normal"
                  color={textColor}
                  fontSize="sm"
                >
                  Keep me logged in
                </FormLabel>
              </FormControl>
              <NavLink to="/auth/forgot-password">
                <Text
                  color={textColorBrand}
                  fontSize="sm"
                  w="124px"
                  fontWeight="500"
                >
                  Forgot password?
                </Text>
              </NavLink>
            </Flex>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={handleSubmitClick}
            >
              Sign In
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Not registered yet?
              <NavLink to="/auth/sign-up">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Create an Account
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
