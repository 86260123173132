export const columnsDataDoctorsOverview = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "USERNAME",
    accessor: "username",
  },
  {
    Header: "JOIN DATE",
    accessor: "date",
  },
  {
    Header: "Mobile Number",
    accessor: "mobile_number",
  },
  {
    Header: "ACTIONS",
    accessor: "actions",
  },
];
