import React from 'react';
import { 
  Flex,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import SearchTableDoctors from "views/admin/doctors/overview/components/SearchTableDoctorsOverivew";
import { columnsDataDoctorsOverview } from "views/admin/doctors/overview/variables/columnsDataDoctorsOverview";
import tableDataDoctorsOverview from "views/admin/doctors/overview/variables/tableDataDoctorsOverview.json";

export default function BrokersOverview() {
    return (
      <>
      <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
        <Card px='0px'>
          <SearchTableDoctors
            tableData={tableDataDoctorsOverview}
            columnsData={columnsDataDoctorsOverview}
          />
        </Card>
      </Flex>
      </>
    );
}
        