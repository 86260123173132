import React from 'react';
import { 
  Flex,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import SearchTablePatients from "views/admin/patients/overview/components/SearchTablePatientsOverivew";
import { columnsDataPatientsOverview } from "views/admin/patients/overview/variables/columnsDataPatientsOverview";
import tableDataPatientsOverview from "views/admin/patients/overview/variables/tableDataPatientsOverview.json";

export default function BrokersOverview() {
    return (
      <>
      <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
        <Card px='0px'>
          <SearchTablePatients
            tableData={tableDataPatientsOverview}
            columnsData={columnsDataPatientsOverview}
          />
        </Card>
      </Flex>
      </>
    );
}
        